import React, { Component } from "react"
import contactStyle from "./contact.module.css"

class Contact extends Component {
  render() {
    return (
      <>
        <article className={contactStyle.container}>
          <h3 className={contactStyle.title}>
            お問い合わせ
          </h3>
          <div className={contactStyle.details}>
            <a href="https://mori-inc.jp/" className={contactStyle.mori}>株式会社 もり</a>
            <address>
              〒150-0013 <br />
              渋谷区 恵比寿 1-23-21-201<br />
              <a href="mailto:info@mori-inc.jp">info@mori-inc.jp</a>
            </address>
          </div>
        </article>
      </>
    )
  }
}

export default Contact
