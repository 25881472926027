import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import storeStyle from "./store.module.css"

class StorePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showStore: false,
      sfixed: false
    }
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const currentScrollpos = window.pageYOffset
    const coverBottom = document.getElementById('cover').clientHeight
    let storeFixed = currentScrollpos > coverBottom

    this.setState({
      sfixed: storeFixed
    })
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      showStore: !prevState.showStore
    }))
  }

  render() {
    const { data } = this.props
    const storesFixed = this.state.sfixed ? storeStyle.isFixed : '';
    const closeActive = this.state.showStore ? storeStyle.isActive : '';
    const sptitleActive = this.state.showStore ? storeStyle.isActive : '';
    const storeOpened = this.state.showStore ? storeStyle.isActive : '';

    return (
      <div className={`${storeStyle.left} ${storesFixed}`} >
        <div className={storeStyle.container}>
          <Img
              fluid={data.Book1.childImageSharp.fluid}
              alt="Introtocreativitybook"
              title="ビジネスパーソンのためのクリエイティブ入門"
              className={storeStyle.pic}
          />
          <br/>
          <button className={storeStyle.bt} onClick={this.toggleMenu}>
            <span className={`${storeStyle.close} ${closeActive}`}>×</span>
            <span className={`${storeStyle.sp} ${sptitleActive}`}>ご購入はこちら</span>
          </button>
          <div className={`${storeStyle.stores} ${storeOpened}`}>
            <a href="https://www.amazon.co.jp/dp/4295404969" target="_blank" rel="noopener noreferrer" className="external">Amazon</a><br />
            <a href="https://www.hmv.co.jp/product/detail/11463410" target="_blank" rel="noopener noreferrer" className="external">HMV & BOOKS online</a><br />
            <a href="https://honto.jp/netstore/pd-book_30735850.html" target="_blank" rel="noopener noreferrer" className="external">honto</a><br />
            <a href="https://store-tsutaya.tsite.jp/item/sell_book/9784295404965.html" target="_blank" rel="noopener noreferrer" className="external">TSUTAYA</a><br />
            <a href="https://aoyamabc.jp/collections/best/products/%E5%8E%9F%E9%87%8E%E5%AE%88%E5%BC%98-%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%83%91%E3%83%BC%E3%82%BD%E3%83%B3%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AE%E3%82%AF%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%86%E3%82%A3%E3%83%96%E5%85%A5%E9%96%80" target="_blank" rel="noopener noreferrer" className="external">青山ブックセンター本店</a><br />
            <a href="https://www.kinokuniya.co.jp/f/dsg-01-9784295404965" target="_blank" rel="noopener noreferrer" className="external">紀伊國屋書店</a><br />
            <a href="https://7net.omni7.jp/detail/1107157826" target="_blank" rel="noopener noreferrer" className="external">セブンネットショッピング</a><br />
            <a href="https://www.yodobashi.com/product/100000009003370132/" target="_blank" rel="noopener noreferrer" className="external">ヨドバシ・ドット・コム</a><br />
            <a href="https://books.rakuten.co.jp/rb/16580400/" target="_blank" rel="noopener noreferrer" className="external">楽天ブックス</a><br/>
          </div>
        </div>
      </div>
    )
  }
}

const Store = props => (
  <StaticQuery
    query={graphql`
      query Store {
        Book1: file(relativePath: { eq: "creative-book.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <StorePage data={data} {...props} />}
  />
)

export default Store
