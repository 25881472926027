import React, { Component } from "react"
import movieStyle from "./movie.module.css"
import Video from "../components/video"

class Movie extends Component {
  render() {
    return (
      <>
        <article className={movieStyle.container}>
          <h2 className={movieStyle.title}>
            心を動かす作品はどう発想するのか？<br />
            第三章「クリエイティブ必勝法」で<br />
            紹介された著者作品とリファレンス
          </h2>
          <section>
            <h4>NTTドコモ: 森の木琴</h4>
            <div className={movieStyle.iframeWrap}>
              <Video
                videoSrcURL="https://www.youtube.com/embed/C_CDLBTJD4M"
                videoTitle="森の木琴"
                className={movieStyle.video}
              />
            </div>
            <h4>
              <span className={movieStyle.gray}>Reference:</span><br/>
              Sony Bravia: Balls
            </h4>
            <Video
              videoSrcURL="https://www.youtube.com/embed/2l_4SOEVraU"
              videoTitle="Balls"
              className={movieStyle.video}
            />
            <p className={movieStyle.caption}>詳細は本書84ページ</p>
          </section>
          <hr className={movieStyle.hrs} />
          <section>
            <h4>OK Go: I Won't Let You Down</h4>
            <div className={movieStyle.iframeWrap}>
              <Video
                videoSrcURL="https://www.youtube.com/embed/u1ZB_rGFyeU"
                videoTitle="OK Go: I Won't Let You Down"
                className={movieStyle.video}
              />
            </div>
            <h4>
              <span className={movieStyle.gray}>Reference:</span><br/>
              Busby Berkeley
            </h4>
            <Video
              videoSrcURL="https://www.youtube.com/embed/PNCwYuXndP0"
              videoTitle="The Musical Magic of Busby Berkeley"
              className={movieStyle.video}
            />
            <p className={movieStyle.caption}>詳細は本書75ページ</p>
          </section>
          <hr className={movieStyle.hrs} />
          <section>
            <h4>Honda. Great Journey.</h4>
            <div className={movieStyle.iframeWrap}>
              <Video
                videoSrcURL="https://www.youtube.com/embed/w8kGOjGXqsU"
                videoTitle="Honda. Great Journey."
                className={movieStyle.video}
              />
            </div>
            <h4>
              <span className={movieStyle.gray}>Reference:</span><br/>
              メタルギアソリッド ピースウォーカー
            </h4>
            <Video
              videoSrcURL="https://www.youtube.com/embed/LCrMDDn5qMw"
              videoTitle="メタルギアソリッド ピースウォーカー"
              className={movieStyle.video}
            />
            <p className={movieStyle.caption}>詳細は本書85ページ</p>
            <h4>
              <span className={movieStyle.gray}>Reference:</span><br/>
              Toccata for Toy Trains
            </h4>
            <Video
              videoSrcURL="https://www.youtube.com/embed/oorg2q0D8hs"
              videoTitle="Toccata for Toy Trains"
              className={movieStyle.video}
            />
            <p className={movieStyle.caption}>詳細は本書94ページ</p>
          </section>
          <hr className={movieStyle.hrs} />
          <section>
            <h4>POLA リクルートフォーラム</h4>
            <div className={movieStyle.iframeWrap}>
              <Video
                videoSrcURL="https://www.youtube.com/embed/iftyUuire54"
                videoTitle="POLA リクルートフォーラム 2016"
                className={movieStyle.video}
              />
            </div>
            <h4>
              <span className={movieStyle.gray}>Reference:</span><br/>
              マララ・ユサフザイさん　<div className={movieStyle.marara}>ノーベル平和賞受賞スピーチ</div>
            </h4>
            <Video
              videoSrcURL="https://www.youtube.com/embed/yT4Fpq7XKy4"
              videoTitle="マララ・ユサフザイさん ノーベル平和賞受賞スピーチ"
              className={movieStyle.video}
            />
            <p className={movieStyle.caption}>詳細は本書86ページ</p>
          </section>
        </article>
        <hr className={movieStyle.hr}/>
      </>
    )
  }
}

export default Movie
