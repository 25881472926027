import React, { Component } from "react"
import introStyle from "./intro.module.css"

class Intro extends Component {
  render() {
    return (
      <>
        <article className={introStyle.container}>
          <h2 className={introStyle.title}>
            人間を真に「突き動かすもの」とは<br />
            果たして何なのか？
          </h2>
          <p>この本は一般的なビジネスパーソンのための「クリエイティブ入門」です。</p>
          <p>著者は、大学卒業後、セールスマンとしてキャリアを積んだのちに、34歳からクリエイティブの世界に転じ、国際的なクリエイティブディレクターとして活動するようになりました。著者自身の試行錯誤の体験から、一般的なビジネスパーソンとクリエイターの間にある「断絶」の正体を解き明かし、より多くの人がクリエイティブな発想を持って働けるようになることを目的としています。クリエイティブ初学者はもちろん、広告やクリエイティブ以外の職種の方、「自分はクリエイティブでない」と思っている方にもおすすめです。
          </p>
          <p>世界中で話題になった「NTTドコモ: 森の木琴」「OK Go: I Won't Let You Down」はどうやってできたのか？「POLA リクルートフォーラム」「日本は、義理チョコをやめよう。GODIVA」はなぜ人の心を揺さぶるのか？ AppleやNikeの広告が異質に見える理由は？ クリエイティブな人は何が違うのか？ 心を動かす企画はどうつくる？ そのすべてがこの本に。</p>
        </article>
        <hr className={introStyle.hr}/>
      </>
    )
  }
}

export default Intro
