import React, { Component } from "react"
import newsStyle from "./news.module.css"

class News extends Component {
  render() {
    return (
      <>
        <article className={newsStyle.container}>
          <h3 className={newsStyle.title}>
            ニュース
          </h3>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.06.24</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>Massnaviに『クリエイティブ入門』の著者、原野守弘さんの記事が公開されました。「偶然の出会いが自分の可能性を広げる／もり 代表／クリエイティブディレクター 原野守弘の〈クリ活〉」</span><a href="https://www.massnavi.com/people/1104.html" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.06.01</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>Narrative GENEsに『クリエイティブ入門』の著者、原野守弘さんの記事が公開されました。「感情、直感、運」。原野守弘さんがビジネスパーソンに向けて語るクリエイティブの本質。</span><a href="https://narrativegenes.com/articles/Cb8aC" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.04.16</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>先日の下北沢B&Bでのイベントレポートが公開されました。「いま、人の心を動かすものとは～ 岡本欣也×原野守弘×チャイ子ちゃん®」</span><a href="https://www.advertimes.com/20210416/article347081/" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.04.16</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>【4月16日（金）夜９時より】『クリエイティブ入門』の著者、原野守弘さんが雑誌『広告』編集長の小野直紀さん、博報堂の村山佳奈女さんとClubhouseで本や広告業界について話をします。</span><a href="https://www.joinclubhouse.com/event/P9eb4YAO" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.04.15</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>『Pen Magazine No.517』に『クリエイティブ入門』の著者、原野守弘さんの記事が掲載されています。「ナイキやバーガーキング……コロナや分断の中で時代を見事に切り取った、必見広告4選。」</span><a href="https://www.pen-online.jp/news/design/2020ad_best5/1" className={newsStyle.link}>本誌を再編集したWeb記事はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.04.02</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>音声メディアVOOXにて『クリエイティブ入門』の著者、原野守弘さんのコンテンツが公開されました。</span><a href="https://voox.me/episodes/0a3d6214-b341-442c-8cd7-dd6ca2db156b/" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.03.30</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>【4月2日、9日（金）17:40分頃】J-WAVEの番組、START LINE「THE VOICE x VOGUE CHANGE」に『クリエイティブ入門』の著者、原野守弘さんが２週にわたり出演します。</span><a href="https://www.j-wave.co.jp/original/startline/" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.03.30</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>【4月7日（水）朝９時】渋谷のラジオ・渋谷のコピーライターに『クリエイティブ入門』の著者、原野守弘さんが出演します。</span><a href="https://shiburadi.com/timetable/%E6%B8%8B%E8%B0%B7%E3%81%AE%E3%82%B3%E3%83%94%E3%83%BC%E3%83%A9%E3%82%A4%E3%82%BF%E3%83%BC" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.03.29</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>VOGUE CHANGEに『クリエイティブ入門』の著者、原野守弘さんのインタビュー記事が掲載されました。「広告はアクティビズムになり得るのか。クリエイティブディレクター、原野守弘に聞く。【メディアとダイバーシティ Vol.3】」</span><a href="https://www.vogue.co.jp/change/article/media-and-diversity-morihiro-harano-interview" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.03.29</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>宣伝会議「3月の広告・マーケティング関連新刊６選」で『クリエイティブ入門』が紹介されました。</span><a href="https://mag.sendenkaigi.com/senden/202104/book/021093.php" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.03.24</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>代官山 蔦屋書店のポッドキャストで『クリエイティブ入門』が紹介されました。「"好き！"からはじまるみんなで読みたいクリエイティブ入門」</span><a href="https://anchor.fm/4902/episodes/08------etabu1" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.03.23</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>VOGUE CHANGE【VOGUE BOOK CLUB】に『クリエイティブ入門』の書評が掲載されました。「原野守弘『クリエイティブ入門』に知る、炎上しない広告のセオリー。」</span><a href="https://www.vogue.co.jp/change/article/vogue-book-club-a-guide-to-creativity" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.03.12</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>【受付終了】【3月26日（金）19時より】青山ブックセンター本店にて『クリ活2クリエイターの就活本』著者の尾上永晃さんと『クリエイティブ入門』著者の原野守弘さんによるトークイベント「クリエイティブディレクター原野守弘のクリ活～クリ活編集長&電通プランナーの尾上永晃が公開インタビュー～」が開催されました。</span>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.03.01</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>【受付終了】【3月18日（木）20時より】本屋B&B（リアルタイム配信イベント）にて『クリエイティブ入門』著者の原野守弘さんと『ステートメント宣言』著者の岡本欣也さん、チャイ子ちゃん®︎によるトークイベント「結局、『一つ』にたどりつく。」が開催されました。</span>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.03.01</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>「月刊ブレーン 2021年４月号」（宣伝会議）の「エディターズブックセレクト」に『クリエイティブ入門』が掲載されました。</span><a href="https://www.sendenkaigi.com/books/back-number-brain/detail.php?id=24959" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.02.26</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>NIKKEI STYLEにて『クリエイティブ入門』が紹介されました。</span><a href="https://style.nikkei.com/article/DGXMZO69403010V20C21A2000000?channel=DF030920184323" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.02.25</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>【満員御礼・受付終了】【3月19日（金）19時より】青山ブックセンター本店にて『クリエイティブ入門』著者の原野守弘さんと『TIMELESS』著者の河尻亨一さんによるトークイベント「ロールモデルと愛と尊敬。好きから生まれる新しい世界」が開催されました。</span>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.02.24</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>『クリエイティブ入門』が大阪芸術大学のお知らせに掲載されました。</span><a href="https://www.osaka-geidai.ac.jp/whatsnew/publication_businessperson" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.02.22</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>日経新聞朝刊に『クリエイティブ入門』新聞広告が掲載されました。</span><a href="https://www.cm-publishing.co.jp/wp-content/uploads/2021/02/210222_%E6%97%A5%E7%B5%8C%E5%8D%8A5_%E8%AA%AD%E6%9B%B8%E8%A1%93_%E3%82%AF%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%86%E3%82%A3%E3%83%95%E3%82%99_ol-01-559x500.jpg" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.02.22</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>梅田蔦屋書店にて『クリエイティブ入門』著者の原野守弘さんによる選書フェアが開催されました。</span><a href="https://store.tsite.jp/umeda/event/business/18757-1629110219.html" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.02.06</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>朝日新聞朝刊に『クリエイティブ入門』の書評が掲載されました。</span><a href="https://book.asahi.com/article/14172309" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.01.30</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>『クリエイティブ入門』が青山ブックセンター本店・店長山下さんの連載「本屋のABC」で紹介されました。</span><a href="https://mag.minne.com/2021/01/30/special_abc-7/" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.01.20</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>『クリエイティブ入門』「はじめに」をnoteに公開しました。</span><a href="https://note.com/mori_harano/n/nb307a161cb00" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
          <div className={newsStyle.details}>
            <p className={newsStyle.date}>2021.01.15</p>
            <p className={newsStyle.description}>
              <span className={newsStyle.text}>青山ブックセンター本店にて『クリエイティブ入門』著者の原野守弘さんによる選書フェアが開催されています。</span><a href="https://twitter.com/Aoyama_book/status/1349956331264856065" className={newsStyle.link}>詳細はこちら</a>
            </p>
          </div>
        </article>
        <hr className={newsStyle.hr} />
      </>
    )
  }
}


export default News
