import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Video from "../components/video"
import profileStyle from "./profile.module.css"

class ProfilePage extends Component {
  render() {
    const { data } = this.props
    return (
      <>
        <article className={profileStyle.container}>
          <div className={profileStyle.author}>
            <Img
              fluid={data.Author.childImageSharp.fluid}
              alt="author"
              title="原野守弘"
              className={profileStyle.pic}
            />
            <div className={profileStyle.name}>
              <h2>原野 守弘<br/>
              <small>Morihiro Harano</small>
              </h2>
              <p>
                株式会社 もり<br />
                代表 / クリエイティブディレクター
              </p>
            </div>
          </div>
          <p>
            電通、ドリル、PARTYを経て、2012年11月、株式会社もりを設立、代表に就任。<br/>
            「NTTドコモ: 森の木琴」「OK Go: I Won’t Let You Down」「Honda. Great Journey.」「POLA リクルートフォーラム」「日本は、義理チョコをやめよう。GODIVA」などを手がける。TED: Ads Worth Spreading、MTV Video Music Awards、D&AD Yellow Pencil、カンヌ国際広告祭 金賞、One Show 金賞、Spikes Asia グランプリ、AdFest グランプリ、ACC グランプリ、グッドデザイン賞 金賞、Penクリエイターアワード2017など、内外で受賞多数。
          </p>
          <br />
          <div className={profileStyle.iframeWrap}>
              <Video
                videoSrcURL="https://www.youtube.com/embed/zlANyCYdIAE"
                videoTitle="The Works of Morihiro Harano"
                className={profileStyle.video}
              />
            </div>
        </article>
        <hr className={profileStyle.hr}/>
      </>
    )
  }
}

const Profile = props => (
  <StaticQuery
    query={graphql`
      query Profile {
        Author: file(relativePath: { eq: "harano.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <ProfilePage data={data} {...props} />}
  />
)

export default Profile
