import React from "react"
import SEO from "../components/seo"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import Cover from "../components/cover"
import Store from "../components/store"
import Intro from "../components/intro"
import News from "../components/news"
import Profile from "../components/profile"
import Movie from "../components/movie"
import Review from "../components/review"
import Contact from "../components/contact"

library.add(fab, fas, far)

export default () => (
  <div>
    <SEO title="ビジネスパーソンのためのクリエイティブ入門" />
    <header>
      <Cover />
    </header>
    <main className="main">
      <Store />
      <section className="right">
        <Intro />
        <Profile />
        <Movie />
        <News />
        <Review />
        <Contact />
      </section>
    </main>
  </div>
)
