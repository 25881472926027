import React, { Component } from "react"
import coverStyle from "./cover.module.css"

class Cover extends Component {
  render() {
    return (
      <div id="cover" className={coverStyle.container}>
        <h1 className={coverStyle.title}>
          ビジネスパーソンのためのクリエイティブ入門
        </h1>
      </div>
    )
  }
}

export default Cover
